import GloLayout from 'lowcode/common/GloLayout';
import './index.scss';

export default function Content({ id, data = {} }: any) {
    const { type, color, title, position } = data;
    const compStyle = {
        color: color,
    };
    return (
        <GloLayout className={`editor-text-title position-${position}`} id={id} style={compStyle}>
            {type === 'H1' && <h1>{title}</h1>}
            {type === 'H2' && <h2>{title}</h2>}
            {type === 'H3' && <h3>{title}</h3>}
            {type === 'H4' && <h4>{title}</h4>}
            {type === 'H5' && <h5>{title}</h5>}
        </GloLayout>
    );
}
