import { connect } from 'mqtt';
import { useEffect, useState } from 'react';

import { APP_BY_LANG } from '@/constants';
import type { EfClient, Options, Receiver } from './types';

// mh200 活动id
const activityMap: any = {
    us: 1,
    jp: 2,
};
/**
 * 生成uuid函数
 * @returns {string}
 */
function _uuid() {
    let d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
}
/**
 * 启动mqtt连接
 *
 * @param {String} url 连接的具体target url
 * @param {Array} topics 全部的主题数组
 * @param {Strign} locale 多语言
 * @param {Array} subscribes 需要订阅的主题数组
 * @returns {Object} 返回订阅主题的数据对象集合
 */
export default function wss({ url, topics, locale, subscribes, activityId }: Options): any {
    const clientId = _uuid();
    const appInfo = APP_BY_LANG[locale || ''];
    const appId = appInfo?.appId || '';
    const activityIdNum = activityId || activityMap[locale || ''] || activityMap['us'];
    // const options: IClientOptions = {
    //     keepalive: 30,
    //     protocolVersion: 4,
    //     clientId: clientId,
    //     clean: true,
    //     reconnectPeriod: 1000,
    //     connectTimeout: 30 * 1000,
    //     username: 'websiteclient',
    //     password: 'Ecoflow2022',
    // }

    /** 获取配置path和初始数据 */
    const paths = topics.filter((item) => subscribes.includes(item.name));

    const [receiver, setReceiver] = useState<Receiver>({});
    const [client, setClient] = useState<EfClient>(null);

    useEffect(() => {
        mqttConnect();
    }, []);

    // 根据主题名字查订阅主题
    const queryPathByName = (name: string) => {
        if (!name) return '';

        const target = topics.filter((item: any) => item.name === name);

        if (target.length > 0) {
            return target[0].path.replace(':appId', appId).replace(':actId', activityIdNum);
        }
        return '';
    };

    // 根据订阅主题查名字
    const queryNameByPath = (path: string) => {
        if (!path) return '';

        const target = topics.filter((item: any) => item.path.replace(':appId', appId).replace(':actId', activityIdNum) === path);

        if (target.length > 0) {
            return target[0].name;
        }
        return '';
    };

    // mqtt链接
    const mqttConnect = () => {
        setClient(
            connect(url, {
                keepalive: 30,
                protocolVersion: 4,
                clientId: clientId,
                clean: true,
                reconnectPeriod: 1000,
                connectTimeout: 30 * 1000,
                username: String(process.env.NEXT_PUBLIC_WS_USERNAME) || 'websiteclient',
                password: String(process.env.NEXT_PUBLIC_WS_PASSWORD) || 'Ecoflow2022',
            }),
        );
    };

    // mqtt订阅主题
    const mqttSubscribe = (name: string) => {
        client?.subscribe(queryPathByName(name));
    };

    // mqtt取消订阅主题
    const mqttUnsubscribe = (name: string) => {
        client?.unsubscribe(queryPathByName(name));
    };

    useEffect(() => {
        if (client) {
            try {
                client.on('connect', () => {
                    paths.forEach((element: any) => {
                        client.subscribe(element.path.replace(':appId', appId).replace(':actId', activityIdNum));
                    });
                });
                client.on('error', (err) => {
                    console.error('Mqtt connection error: ', err);
                    // client.end()
                });
                client.on('message', (topic: any, message) => {
                    if (topic) {
                        const payload = JSON.parse(message.toString());

                        setReceiver({
                            data: payload,
                            topic: queryNameByPath(topic),
                        });
                    }
                });
            } catch (err) {
                console.log(err);
            }
        }

        return () => {
            client?.end();
        };
    }, [client]);

    return {
        receiver,
        mqttSubscribe,
        mqttUnsubscribe,
    };
}
