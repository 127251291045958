'use client';
import { useRef, useState } from 'react';
import styles from './index.module.scss';

export default function EditorVideo({ className = '', autoPlay, loop = false, poster, posterH5, src, pc, h5 }: any) {
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const [isPlay, setIsPlay] = useState(false);
    const pcSrc = src || pc;
    const twoSrc = pcSrc && h5;
    // 点击播放
    const playHandle = () => {
        setIsPlay(true);
        if (pcSrc) {
            const video1: any = videoRef1?.current;
            video1?.play();
            video1?.addEventListener('ended', () => {
                setIsPlay(false);
            });
        }
        if (h5) {
            const video2: any = videoRef2?.current;
            video2?.play();
            video2?.addEventListener('ended', () => {
                setIsPlay(false);
            });
        }
    };
    return (
        <div className={`${styles.video} ${className} lowcode-video`}>
            {pcSrc && (
                <video ref={videoRef1} playsInline muted autoPlay={autoPlay} loop={loop} poster={poster} className={twoSrc ? 'lowcode-video-pc' : ''}>
                    <source src={pcSrc} />
                </video>
            )}
            {h5 && (
                <video
                    ref={videoRef2}
                    playsInline
                    muted
                    autoPlay={autoPlay}
                    loop={loop}
                    poster={posterH5 || poster}
                    className={twoSrc ? 'lowcode-video-h5' : ''}
                >
                    <source src={h5} />
                </video>
            )}
            {!autoPlay && !isPlay && (
                <svg
                    className="lowcode-video-icon"
                    onClick={playHandle}
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                >
                    <path
                        d="M94.1667 49.8611C94.1667 74.3304 74.3304 94.1667 49.8611 94.1667C25.3918 94.1667 5.55557 74.3304 5.55557 49.8611C5.55557 25.3918 25.3918 5.55557 49.8611 5.55557C74.3304 5.55557 94.1667 25.3918 94.1667 49.8611Z"
                        stroke="white"
                        strokeWidth="2.22222"
                    />
                    <path
                        d="M37.7783 67.9227V34.0886C37.7783 32.3267 39.7308 31.2655 41.2093 32.2239L66.1396 48.3835C67.4577 49.2378 67.4975 51.1526 66.2162 52.0611L41.2858 69.7356C39.8142 70.7789 37.7783 69.7266 37.7783 67.9227Z"
                        fill="white"
                    />
                </svg>
            )}
        </div>
    );
}
