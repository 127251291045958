import GloLayout from 'lowcode/common/GloLayout';
import EfActivityCardA, { EfActivityCardAProps } from 'elem/ui/ef-activity-card-a';
import { Fragment, useEffect, useState } from 'react';
import { fetchPost } from 'lowcode/utils/service/FetchClient';
import { baseUrl } from 'lowcode/utils/service/baseUrl';
import styles from './index.module.scss';
import getLocal from '@/app/utils/getLocal';

export default function Content({ id, data = {} }: any) {
    const { title, desc, list = [], cardType } = data;
    const { locale } = getLocal();
    const [list2, setList2] = useState<EfActivityCardAProps[]>(list);

    useEffect(() => {
        // 发起请求
        const getItemPriceHandle = async (variantIds: string[]) => {
            const res = await fetchPost(`${baseUrl(locale)}/ProductCaculation/productInfoList`, locale, {
                variantIds,
            });
            if (res?.code === '0') {
                const list = res.data || [];
                const objA: any = {};
                list.forEach((item: any, index: number) => {
                    objA[`${item.variantId}`] = item;
                });
                return objA;
            }
            return {};
        };
        // 请求全部
        const initAllHandle = async () => {
            const variantIds: string[] = [];
            // 先取回接口的值(shopify的价格,图片等)
            for (let i = 0; i < list.length; i++) {
                const itemA = list[i];
                const variantId = itemA.variantId;
                // 已有值,不发起2次请求
                if (!variantIds.includes(variantId)) {
                    variantIds.push(variantId);
                }
            }
            const priceObject2 = await getItemPriceHandle(variantIds); // 请求拿到数据
            // 拿到参数后,2次遍历改变
            const listB = JSON.parse(JSON.stringify(list));
            for (let i = 0; i < listB.length; i++) {
                const itemA = listB[i];
                const keyA = `${itemA.variantId}`;
                const objA = priceObject2[keyA];
                if (objA) {
                    itemA.img = itemA.img || objA.img;
                    itemA.discountPrice = objA.price;
                    itemA.originalPrice = objA.price;
                    itemA.inventoryQuantity = objA.inventoryQuantity || undefined;
                    itemA.link_buy = `https://${locale}.ecoflow.com/cart/${objA.variantId}:1?channel=buy_button`;
                }
            }
            setList2(JSON.parse(JSON.stringify(listB)));
        };
        initAllHandle();
    }, [list]);

    return (
        <GloLayout className={`${styles.comp} temp-activity-card`} id={id}>
            {title && <h2 className="activity-card-title">{title}</h2>}
            {desc && <p className="activity-card-desc">{desc}</p>}
            <div className={`activity-card-in activity-card-${cardType}`}>
                {list2.map((item: EfActivityCardAProps, index: number) => {
                    return (
                        <Fragment key={index}>
                            <EfActivityCardA {...item} type={cardType} />
                        </Fragment>
                    );
                })}
            </div>
        </GloLayout>
    );
}
