export default function ContentImg({ id, data = {} }: any) {
    const { pc, h5, alt } = data;

    return (
        <>
            {h5 && <img src={h5} aria-label={alt} className="editor-resource-img-video-h5" />}
            <img src={pc} aria-label={alt} className="editor-resource-img-video-pc" />
        </>
    );
}
