'use client';

import { w_desktop_max, w_desktop } from 'lowcode/common/getDevice';

const width1 = w_desktop_max - 1;
const width2 = w_desktop - 1;
export default function DefaultStyle({ id, attr = {} }: any) {
    const { marginT1, marginT2, marginT3 } = attr;
    if (marginT1 === undefined && marginT2 === undefined && marginT3 === undefined) {
        return null;
    }
    return null;
}
