import classNames from 'classnames';
import React from 'react';
import './index.scss';

interface EfActivityScoreProps {
    /**
     * 样式名
     */
    className?: string;
    /**
     * 左侧的倍数文案
     *
     * 如:5倍
     */
    scaleText?: string;
    /**
     * 右侧的多少分数文案
     *
     * 5,530ポイント
     */
    scoreText?: string;
}

/**
 * 维护: 谢保林
 *
 * 活动卡片-分数
 */
const EfActivityScore: React.FC<EfActivityScoreProps> = ({ scaleText, scoreText, className }) => {
    const prefixCls = `ef-activity-score`;
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
    });

    return (
        <div className={classes}>
            <div className={`${prefixCls}-icon`}></div>
            <div className={`${prefixCls}-l`}>{scaleText}</div>
            <div className={`${prefixCls}-r`}>{scoreText}</div>
        </div>
    );
};

export default EfActivityScore;
