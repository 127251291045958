export default function ContentVideo({ id, data = {} }: any) {
    const { pc, h5, poster, posterH5, alt } = data;

    return (
        <>
            {h5 && <video src={pc} poster={posterH5} aria-label={alt} playsInline autoPlay={true} loop={true} muted className="editor-resource-img-video-h5" />}
            <video src={pc} poster={poster} aria-label={alt} playsInline autoPlay={true} loop={true} muted className="editor-resource-img-video-pc" />
        </>
    );
}
