export default function CompPcs({ prefixCls }: { prefixCls: string }) {
    return (
        <div className={`${prefixCls}-pcs`}>
            <div className={`${prefixCls}-pcs-item`}>
                <div className={`${prefixCls}-pcs-item-line1`}>$999.9</div>
                <div className={`${prefixCls}-pcs-item-line2`}>1pc</div>
            </div>
            <div className={`${prefixCls}-pcs-line`}></div>
            <div className={`${prefixCls}-pcs-item`}>
                <div className={`${prefixCls}-pcs-item-line1`}>2%OFF</div>
                <div className={`${prefixCls}-pcs-item-line2`}>2pc</div>
            </div>
            <div className={`${prefixCls}-pcs-line`}></div>
            <div className={`${prefixCls}-pcs-item`}>
                <div className={`${prefixCls}-pcs-item-line1`}>3%OFF</div>
                <div className={`${prefixCls}-pcs-item-line2`}>3pc</div>
            </div>
        </div>
    );
}
