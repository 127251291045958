'use cilent';

import { fetchGet } from 'lowcode/utils/service/FetchClient';
import { getHostApi } from 'lowcode/utils/service/getHostApi';

export const queryByShopifyProductId = async (locale: string, params: any) => {
    console.log('aa', getHostApi(locale));
    const res = await fetchGet(
        `${getHostApi(locale).NEXT_PUBLIC_LOGIN_HOST}/website/navigationBar/queryByShopifyProductId?productId=${params.productId}`,
        locale,
    );
    return res?.data || {};
};
