'use client';
import gtm from '@/utils/gtm';
import styles from 'lowcode/common/css/bannercard.module.scss';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import EditorVideo from 'lowcode/components/EditorVideo';
import { useEffect, useState } from 'react';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import styles2 from './index.module.scss';

let animationId: any = null;
const duration = 4000; // 动画持续时间（毫秒）
let initAniTime = 0; // 开启动画的时间
let aniFrameTime = 0; // 每一帧动画进入时间
let pause = false; // 用户交互鼠标移入是否暂停
let percent = 0; // 进度
let replay = false; // 暂停后继续播放
let enterTime = 0; // 鼠标移入时间
let leaveTime = 0; // 鼠标移出时间

export default function Content({ id, data, attr, initIndex = 0, isEditStatus = false }: any) {
    const { isAutoPlay, list = [] } = data;
    const [swiper, setSwiper] = useState<any>({});

    // 轮播变化埋点
    const slideChangeHandle = (index: number) => {
        const item = list[index - 1];
        if (item) {
            gtm.push({
                event: 'uaEvent',
                event_name: 'view_banner',
                banner_name: item.title,
                position: `carousel banner_${index}}`,
                nonInteraction: false,
            });
        }
        if (isAutoPlay) {
            initAniTime = 0;
            if (animationId) {
                cancelAnimationFrame(animationId);
                animationId = null;
            }
            doAnimate();
        }
    };

    const hanldleMouseEnter = () => {
        if (!!isAutoPlay && swiper) {
            pause = true;
            replay = false;
            enterTime = new Date().getTime();
            if (animationId) {
                cancelAnimationFrame(animationId);
            }
        }
    };

    const hanldleMouseLeave = () => {
        if (!!isAutoPlay && swiper) {
            pause = false;
            replay = true;
            leaveTime = new Date().getTime();
            doAnimate();
        }
    };

    // 执行动画的函数
    const animate = (_currentTime: number) => {
        if (!initAniTime) {
            initAniTime = new Date().getTime();
        }
        aniFrameTime = new Date().getTime();
        const element = document.querySelector(`.temp-${id} .swiper-box .swiper-pagination .swiper-pagination-bullet-active .after`) as HTMLSpanElement;

        // 计算动画进度（0 到 1 之间的值）
        if (element && swiper) {
            if (!pause) {
                if (replay) {
                    initAniTime = initAniTime + (leaveTime - enterTime);
                    leaveTime = 0;
                    enterTime = 0;
                    replay = false;
                }
                const judgeTime = aniFrameTime - initAniTime;
                const progress = Math.min(judgeTime / duration, 1);
                percent = progress;
                element.style.transform = `translateX(-${100 - 100 * percent}%)`;
                if (judgeTime >= duration) {
                    try {
                        swiper && swiper.slideNext && swiper.slideNext();
                    } catch (e: any) {}
                }
            } else {
                element.style.transform = `translateX(-${100 - 100 * percent}%)`;
            }
            requestAnimationFrame(animate);
        }
    };

    const doAnimate = () => {
        if (animationId) cancelAnimationFrame(animationId);
        animationId = requestAnimationFrame(animate);
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && swiper && isAutoPlay && !isEditStatus) {
            // 启动动画
            doAnimate();
        }
    }, [swiper, isAutoPlay, isEditStatus]);

    useEffect(() => {
        // isEditStatus 编辑状态下不能轮播
        if (isAutoPlay && !isEditStatus && typeof window !== 'undefined') {
            const eles: any = document.querySelectorAll(`.temp-${id} .swiper-box .swiper-pagination .swiper-pagination-bullet`);
            for (let i = 0; i < eles?.length; i++) {
                const existedSpan = eles[i].querySelector('.after') as HTMLSpanElement;
                if (!existedSpan) {
                    const span = document.createElement('span');
                    span.setAttribute('class', 'after');
                    eles[i].appendChild(span);
                }
            }
        }
    }, [isAutoPlay]);

    return (
        <div className={`${styles2.content} temp-BannerSwiper temp-hide temp-${id}`} id={id}>
            <Swiper
                className={`swiper-box ${isAutoPlay ? 'auto-play' : ''}`}
                modules={[Scrollbar, Navigation, Pagination]}
                navigation={true}
                pagination={true}
                loop={!!isAutoPlay}
                slidesPerView={'auto'}
                initialSlide={initIndex || 0}
                onSlideChange={(swiper) => slideChangeHandle(swiper.activeIndex)}
                onSwiper={(swiperIns: any) => setSwiper(swiperIns)}
            >
                {list?.map((item: any, index: number) => {
                    const {
                        hot,
                        hotColor,
                        title,
                        titleColor,
                        desc,
                        descColor,
                        btnBuyText,
                        linkBuy,
                        btnMoreText,
                        linkMore,
                        isVideo,
                        pc,
                        h5,
                        theme,
                        textAlign,
                        textAlignH5,
                        position,
                        positionH5,
                        isPcH5EqualCss,
                    } = item;
                    const hasSrc = pc || h5; //2张图片都没有的话,不显示图片
                    const textAlignCls = `textAlign-${textAlign}`;
                    const positionCls = `position-${position}`;
                    const textAlignH5B = isPcH5EqualCss ? textAlign : textAlignH5 || textAlign;
                    const positionH5B = isPcH5EqualCss ? position : positionH5 || position;
                    const textAlignClsH5 = `textAlign-h5-${textAlignH5B}`;
                    const positionClsH5 = `position-h5-${positionH5B}`;

                    return (
                        <SwiperSlide
                            className={`swiper-item ${styles.bannercard} theme-${
                                theme === '#000000' ? 'black' : theme === '#FFFFFF' ? 'white' : theme
                            } ${textAlignCls} ${positionCls} ${textAlignClsH5} ${positionClsH5}`}
                            key={index}
                            onMouseEnter={hanldleMouseEnter}
                            onMouseLeave={hanldleMouseLeave}
                        >
                            <div className="banner-info">
                                <div className="banner-info-in">
                                    {hot && (
                                        <div className="banner-hot" style={{ color: hotColor || null }}>
                                            {hot}
                                        </div>
                                    )}
                                    {title && (
                                        <h2 className="banner-title" style={{ color: titleColor || theme || null }}>
                                            {title}
                                        </h2>
                                    )}
                                    {desc && (
                                        <p className="banner-desc" style={{ color: descColor || theme || null }}>
                                            {desc}
                                        </p>
                                    )}
                                    <div className="banner-btns">
                                        {btnBuyText && linkBuy && (
                                            <EditorLink
                                                href={linkBuy}
                                                className="banner-link link-buy"
                                                dataLayer={{
                                                    event_name: 'buy',
                                                    product_name: title,
                                                    position: 'Homepage',
                                                }}
                                                style={{ borderColor: theme || null, background: theme || null }}
                                            >
                                                {btnBuyText}
                                            </EditorLink>
                                        )}
                                        {btnMoreText && linkMore && (
                                            <EditorLink
                                                href={linkMore}
                                                className="banner-link link-more"
                                                dataLayer={{
                                                    event_name: 'select_banner',
                                                    banner_name: title,
                                                    position: `carousel banner_${index}}`,
                                                    type: 'Learn more',
                                                }}
                                                style={{ borderColor: theme || null, color: theme || null }}
                                            >
                                                {btnMoreText}
                                            </EditorLink>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {hasSrc && (
                                <div className="banner-img">
                                    {!isVideo && <EditorImg pc={pc} h5={h5} />}
                                    {isVideo && <EditorVideo autoPlay={true} loop={true} src={pc} h5={h5} />}
                                </div>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}
