import GloLayout from 'lowcode/common/GloLayout';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { position, color, bgColor, title } = data;
    const compStyle = {
        '--editor-text-richtext-color': color,
        '--editor-text-richtext-bg-color': bgColor,
    };

    return (
        <GloLayout id={id} className={`editor-text-richtext position-${position}`} style={compStyle}>
            {title && (
                <div
                    className="richtext-title"
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                ></div>
            )}
        </GloLayout>
    );
}
