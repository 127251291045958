export const hideCardForTime = (timeStart: string | undefined, timeEnd: string | undefined) => {
    const tNow = Date.now();
    if (timeStart && timeEnd) {
        const tStart = new Date(timeStart).getTime();
        const tEnd = new Date(timeEnd).getTime();
        // 当前时间小于开始时间,不展示
        // 当前时间大于结束时间,不展示
        if (tNow < tStart || tNow > tEnd) {
            return true;
        }
    } else if (timeStart) {
        const tStart = new Date(timeStart).getTime();
        // 当前时间小于开始时间,不展示
        if (tNow < tStart) {
            return true;
        }
    }
    return false; //展示卡片
};
