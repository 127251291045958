'use client';

import { w_desktop } from 'lowcode/common/getDevice';

export default function StyleComp({ id, attr = {} }: any) {
    const { marginTPc, marginBPc, marginTH5, marginBH5 } = attr;
    return (
        <>
            <style>
                {`
                @media only screen and (min-width: ${w_desktop}px) {
                    .temp-${id} {
                        margin-top: ${marginTPc};
                        margin-bottom: ${marginBPc};
                    }
                }
                @media only screen and (max-width: ${w_desktop}px) {
                    .temp-${id} {
                        margin-top: ${marginTH5};
                        margin-bottom: ${marginBH5};
                    }
                }
            `}
            </style>
        </>
    );
}
