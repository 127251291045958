import { FormatMoneyLocal } from '@elem/ui-common/utils/FormatMoneyLocal';

export default function CompPriceB({ prefixCls, item, textA, locale }: { prefixCls: string; item: any; textA: any; locale: string }) {
    return (
        <div className={`${prefixCls}-footer-price-b`}>
            <span className={`${prefixCls}-footer-price-b-ms1`}>
                {FormatMoneyLocal(item.originalPrice || 0, locale)}
                {textA.member_only_none}
            </span>
            <span className={`${prefixCls}-footer-price-b-ms2`}>{FormatMoneyLocal(item.discountPrice || 0, locale)}</span>
        </div>
    );
}
