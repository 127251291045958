import classNames from 'classnames';
import GloLayout from 'lowcode/common/GloLayout';
import ContentImg from './comp/img';
import ContentVideo from './comp/video';

export default function Content({ id, data = {} }: any) {
    const { isVideo, imgBgType = 'default', imgPosition = 'center', widthLayout = 'default', widthPc } = data;
    const prefixCls = `editor-resource-img-video`;
    const classes = classNames(prefixCls, {
        [`${prefixCls}-imgBgType-${imgBgType}`]: imgBgType,
        [`${prefixCls}-imgPosition-${imgPosition}`]: imgPosition,
        [`${prefixCls}-widthLayout-${widthLayout}`]: widthLayout,
    });
    const compStyle: any = {};
    if (widthPc) {
        compStyle['--resource-img-video-w'] = `${widthPc}px`;
    }
    return (
        <GloLayout className={classes} id={id} style={compStyle}>
            <div className="editor-resource-img-video-in">
                <div className="editor-resource-img-video-box">
                    {!isVideo && <ContentImg data={data} />}
                    {isVideo && <ContentVideo data={data} />}
                </div>
            </div>
        </GloLayout>
    );
}
