import type { TopicItem } from '@/utils/mqtt-client/types';

// 订阅主题
const topics: TopicItem[] = [
    {
        name: 'number',
        path: '/crowdFunding/flow/:appId/:actId',
        data: {},
    },
    {
        name: 'backers',
        path: '/crowdFunding/product/sales/:appId/:actId',
        data: [],
    },
    {
        name: 'addedMainComments',
        path: '/comment/content/create/:appId/:actId',
        data: [],
    },
    {
        name: 'addedSubComments',
        path: '/comment/subcontent/create/:appId/:actId',
        data: [],
    },
    {
        name: 'addedDelComments',
        path: '/comment/content/detete/:appId/:actId',
        data: [],
    },
    {
        name: 'inventory',
        path: '/shopify/product/inventory/:appId',
        data: [],
    },
    {
        name: 'seckill',
        path: '/shopify/product/ecocreditsday/seckill/:appId',
        data: [],
    },
    {
        name: 'ecocreditsYear',
        path: '/shopify/product/ecocreditsYear/seckill/:appId',
        data: [],
    },
    {
        name: 'updatePoints',
        path: '/integral/common/topic/:appId/:actId',
        data: [],
    },
    {
        name: 'flashSale',
        path: '/integral/seckillcampaign/topic/:actId',
        data: [],
    },
];

export default topics;
