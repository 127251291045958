'use client';

import { getCookie } from 'lowcode/common/ABTestClient/ABTestCookie';

// 用户是否登陆
export const getIsLogin = () => {
    if (typeof window === 'undefined') {
        return false;
    }
    const userInfoA = decodeURIComponent(getCookie('userInfo') || '{}');
    const userInfoB = JSON.parse(userInfoA) || {};

    return !!userInfoB.token;
};
