import getLocal from '@/app/utils/getLocal';

import EfLink from '../../ef-link';

export default function CompLinksA({
    prefixCls,
    item,
    list_name,
    textA,
    inventoryQuantity,
    buyHref,
    buyInCartHandle,
}: {
    prefixCls: string;
    item: any;
    list_name?: string;
    textA: any;
    inventoryQuantity: number;
    buyHref: string;
    buyInCartHandle: any;
}) {
    const { locale = 'us' } = getLocal();
    return (
        <div className={`${prefixCls}-footer-links`}>
            {item.moreLink && (
                <EfLink
                    className={`${prefixCls}-link ${prefixCls}-link-more`}
                    href={item.moreLink}
                    dataLayer={{
                        event: 'uaEvent',
                        event_name: 'select_product',
                        list_name: list_name || item.name || '',
                        product_name: item.name || '',
                        button_name: textA.link_more,
                    }}
                >
                    {textA.link_more}
                </EfLink>
            )}
            {inventoryQuantity > 0 && !item.buyInCart && (
                <EfLink
                    className={`${prefixCls}-link ${prefixCls}-link-buy`}
                    href={buyHref}
                    dataLayer={{
                        event: 'uaEvent',
                        event_name: 'checkout',
                        button_name: `${textA.link_buy}_${item?.name}`,
                    }}
                >
                    {textA.link_buy}
                </EfLink>
            )}
            {inventoryQuantity > 0 && item.buyInCart && (
                <button className={`${prefixCls}-link ${prefixCls}-link-buy`} onClick={buyInCartHandle}>
                    {textA.link_buy}
                </button>
            )}
            {inventoryQuantity === 0 && <span className={`${prefixCls}-link ${prefixCls}-link-buy ${prefixCls}-link-disabled`}>{textA.sold_out}</span>}
        </div>
    );
}
