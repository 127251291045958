import getLocal from '@/app/utils/getLocal';
import { hideCardForTime } from '@elem/ui-common/utils/hideCardForTime';
import classNames from 'classnames';
import editorEventsBus from 'lowcode/utils/editorEventsBus';
import React, { useState } from 'react';
import EfActivityScore from '../ef-activity-score';
import EfImg from '../ef-img';
import CompCartA from './comps/CompCartA';
import CompEcoCredits from './comps/CompEcoCredits';
import CompLinksA from './comps/CompLinksA';
import CompPcs from './comps/CompPcs';
import CompPriceA from './comps/CompPriceA';
import CompPriceB from './comps/CompPriceB';
import CompPriceC from './comps/CompPriceC';
import CompPriceD from './comps/CompPriceD';
import './index.scss';
import { langText } from './langText';

export interface EfActivityCardAProps {
    /**
     * 主图
     */
    img?: string;
    /**
     * 产品 productId
     */
    productId: string;
    /**
     * 产品 variantId
     */
    variantId: string;
    /**
     * 购买链接
     */
    buyHref?: string; //购买链接
    /**
     * 主产品实际价格
     */
    discountPrice: number;
    /**
     * 主产品划线价格
     */
    originalPrice: number;
    /**
     * 默认购买数量
     */
    quantity?: number;
    /**
     * 库存
     */
    inventoryQuantity?: number; //主产品
    /**
     * 产品开始展示时间
     */
    timeStart?: string;
    /**
     * 产品结束展示时间
     */
    timeEnd?: string;
    /**
     * 优惠券-优惠码
     */
    coupon?: string;
    /**
     * 优惠券-优惠码左侧文案介绍
     */
    couponText?: string;
    /**
     * 优惠券-优惠提示语
     */
    couponTips?: string;
    /**
     * 是否点击后添加到购物车
     */
    buyInCart?: boolean;
    /**
     * 文案-主标题
     */
    name?: string;
    /**
     * 文案-特性
     */
    power?: string;
    /**
     * 文案-描述
     */
    desc?: string;
    /**
     * 更多详情链接
     */
    moreLink?: string;
    /**
     * 卡片布局位置
     */
    position?: 'default' | 'imgL' | 'imgR' | 'textC' | 'card4';
    /**
     * 样式名
     */
    className?: string;
    /**
     * 卡片类型
     */
    type: 'default' | 'imgLR' | 'addCart3' | 'column4' | 'column3';
    /**
     * 用于埋点的list_name
     */
    list_name?: string;
}

/**
 * 维护:谢保林
 *
 * 用于会员活动的商品列表卡片
 */
const EfActivityCardA: React.FC<EfActivityCardAProps> = ({
    img,
    productId,
    variantId,
    buyHref,
    discountPrice,
    originalPrice,
    quantity,
    inventoryQuantity,
    timeStart,
    timeEnd,
    coupon,
    couponText,
    couponTips,
    buyInCart,
    name,
    power,
    desc,
    moreLink,
    position,
    className,
    type,
    list_name,
}) => {
    const item = {
        img,
        productId,
        variantId,
        buyHref,
        discountPrice,
        originalPrice,
        quantity,
        inventoryQuantity,
        timeStart,
        timeEnd,
        coupon,
        couponText,
        couponTips,
        buyInCart,
        name,
        power,
        desc,
        moreLink,
        position,
        className,
        type,
        list_name,
    };
    if (!variantId) {
        return null;
    }
    // 如果有传入(有效时间)
    if (hideCardForTime(item.timeStart, item.timeEnd)) {
        return null;
    }
    const { locale } = getLocal();
    const textA = langText[locale] || langText['us'];
    const prefixCls = `ef-activity-card-a`;
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
        [`${prefixCls}-type-${type}`]: type,
        [`${prefixCls}-position-${item.position}`]: item.position,
    });
    const inventoryQuantity2 = item.inventoryQuantity || 0; //主产品的剩余库存
    const [buyHref2, setBuyHref2] = useState(`https://${locale}.ecoflow.com/cart/${item.variantId}:1?channel=buy_button`); //购买链接
    const [quantity2, setQuantity2] = useState(item.quantity || 1); //购买数量
    // 点击购买--打开购物车
    const buyInCartHandle = () => {
        editorEventsBus.emit(
            'efshopingcart-add',
            [
                {
                    item: item,
                    quantity: quantity,
                },
            ],
            (list: any) => {
                //console.log('list', list);
            },
        );
    };

    // 数量发生变化
    const numChange = (quantity: number) => {
        setQuantity2(quantity);
    };

    // 卡片共用部分
    const CardCommon = () => {
        return (
            <>
                <div className={`${prefixCls}-discount`}>
                    <div className={`${prefixCls}-off`}>
                        <span className={`${prefixCls}-off-text`}>
                            <span className={`${prefixCls}-off-text1`}>40%</span>
                            <span className={`${prefixCls}-off-text2`}>OFF</span>
                        </span>
                    </div>
                    <EfActivityScore scaleText={'5倍'} scoreText={`14,493ポイント`} className={`${prefixCls}-score`} />
                </div>

                <div className={`${prefixCls}-text`}>
                    {item.name && <p className={`${prefixCls}-title`}>{item.name}</p>}
                    {item.desc && <p className={`${prefixCls}-desc`}>{item.desc}</p>}
                    {item.power && <p className={`${prefixCls}-subTitle`}>{item.power}</p>}
                </div>
            </>
        );
    };

    // type=addCart3
    const TypeCart3 = () => {
        return (
            <div className={classes}>
                <div className={`${prefixCls}-img`}>
                    <EfImg pc={item.img} />
                </div>
                <div className={`${prefixCls}-msg`}>
                    <CompEcoCredits prefixCls={prefixCls} />
                    <CardCommon />
                    <div className={`${prefixCls}-footer`}>
                        <CompPriceC prefixCls={prefixCls} locale={locale} item={item} textA={textA} />
                        <CompPcs prefixCls={prefixCls} />
                        <CompCartA
                            prefixCls={prefixCls}
                            textA={textA}
                            item={item}
                            quantity={quantity2}
                            inventoryQuantity={inventoryQuantity2}
                            numChange={numChange}
                            buyInCartHandle={buyInCartHandle}
                        />
                    </div>
                </div>
            </div>
        );
    };

    // type = column4
    const TypeColumn4 = () => {
        return (
            <div className={classes}>
                <div className={`${prefixCls}-img`}>
                    <EfImg pc={item.img} />
                </div>
                <div className={`${prefixCls}-msg`}>
                    <CompEcoCredits prefixCls={prefixCls} />
                    <CardCommon />
                    <div className={`${prefixCls}-footer`}>
                        <CompPriceD prefixCls={prefixCls} locale={locale} item={item} textA={textA} />
                        <CompLinksA
                            prefixCls={prefixCls}
                            item={item}
                            textA={textA}
                            list_name={list_name}
                            inventoryQuantity={inventoryQuantity2}
                            buyHref={buyHref2}
                            buyInCartHandle={buyInCartHandle}
                        />
                    </div>
                </div>
            </div>
        );
    };

    // type = column3
    const TypeColumn3 = () => {
        return (
            <div className={classes}>
                <div className={`${prefixCls}-img`}>
                    <EfImg pc={item.img} />
                </div>
                <div className={`${prefixCls}-msg`}>
                    <CompEcoCredits prefixCls={prefixCls} />
                    <CardCommon />
                    <div className={`${prefixCls}-footer`}>
                        <CompPriceA prefixCls={prefixCls} locale={locale} item={item} textA={textA} />

                        <CompPriceB prefixCls={prefixCls} locale={locale} item={item} textA={textA} />

                        <CompLinksA
                            prefixCls={prefixCls}
                            item={item}
                            textA={textA}
                            list_name={list_name}
                            inventoryQuantity={inventoryQuantity2}
                            buyHref={buyHref2}
                            buyInCartHandle={buyInCartHandle}
                        />
                    </div>
                </div>
            </div>
        );
    };

    if (type === 'addCart3') {
        return (
            <>
                <TypeCart3 />
            </>
        );
    }

    if (type === 'column4') {
        return (
            <>
                <TypeColumn4 />
            </>
        );
    }

    if (type === 'column3') {
        return (
            <>
                <TypeColumn3 />
            </>
        );
    }

    // 通用
    return (
        <div className={classes}>
            <div className={`${prefixCls}-img`}>
                <EfImg pc={item.img} />
            </div>
            <div className={`${prefixCls}-msg`}>
                <CardCommon />
                <div className={`${prefixCls}-footer`}>
                    <CompPriceA prefixCls={prefixCls} locale={locale} item={item} textA={textA} />

                    <CompPriceB prefixCls={prefixCls} locale={locale} item={item} textA={textA} />

                    <CompLinksA
                        prefixCls={prefixCls}
                        item={item}
                        textA={textA}
                        list_name={list_name}
                        inventoryQuantity={inventoryQuantity2}
                        buyHref={buyHref2}
                        buyInCartHandle={buyInCartHandle}
                    />
                </div>
            </div>
        </div>
    );
};

export default EfActivityCardA;
