import EfBundlesCalculator from '@elem/ui/ef-bundles-calculator';

export default function CompCartA({
    prefixCls,
    inventoryQuantity,
    textA,
    quantity,
    item,
    numChange,
    buyInCartHandle,
}: {
    prefixCls: string;
    inventoryQuantity: number;
    quantity: number;
    textA: any;
    item: any;
    numChange: any;
    buyInCartHandle: any;
}) {
    return (
        <div className={`${prefixCls}-footer-btns-cart`}>
            <div className={`${prefixCls}-calculator`}>
                <EfBundlesCalculator defaultQuantity={quantity} item={item} callback={numChange} />
            </div>
            {inventoryQuantity > 0 && (
                <button className={`${prefixCls}-link ${prefixCls}-link-buy`} onClick={buyInCartHandle}>
                    {textA.link_buy}
                </button>
            )}
            {inventoryQuantity === 0 && <span className={`${prefixCls}-link ${prefixCls}-link-buy ${prefixCls}-link-disabled`}>{textA.sold_out}</span>}
        </div>
    );
}
