import { FormatMoneyLocal } from '@elem/ui-common/utils/FormatMoneyLocal';

export default function CompPriceA({ prefixCls, item, textA, locale }: { prefixCls: string; item: any; textA: any; locale: string }) {
    return (
        <div className={`${prefixCls}-footer-price-arrow ${prefixCls}-footer-price-a`}>
            <span className={`${prefixCls}-footer-price-arrow-ms1`}>{FormatMoneyLocal(item.discountPrice || 0, locale)}</span>
            <span className={`${prefixCls}-footer-price-arrow-ms2`}>
                <span className={`${prefixCls}-footer-price-arrow-ms2-in`}>{textA.member_only}</span>
            </span>
        </div>
    );
}
