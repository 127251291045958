'use client';
import EditorImg from 'lowcode/components/EditorImg';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import { useState } from 'react';
import styles from './index.module.scss';
import gtm from '@/utils/gtm';

export default function Content({ id, data, attr = {} }: any) {
    const { title = '', position, theme, moreText, moreLink, list = [] } = data;
    const [youtubeLink, setYoutubeLink] = useState<any>([]);
    const trimTitle = title.trim();

    // 播放
    const playHandle = (index: any, title_name: string) => {
        const youtubeLink2 = JSON.parse(JSON.stringify(youtubeLink));
        youtubeLink2.push(index);
        setYoutubeLink(youtubeLink2);
        gtm.push({
            event: 'uaEvent',
            event_name: 'video',
            position: trimTitle,
            title_name,
        });
    };

    return (
        <div className={`${styles.content} temp-hide temp-${id}`} id={id}>
            <DefaultStyle id={id} attr={attr} />
            {trimTitle && <div className="ListYoutube-title">{trimTitle}</div>}
            <div className="ListYoutube-in">
                {list?.map((item: any, index: number) => {
                    const { title, desc, pc, h5 } = item;
                    const hasSrc = pc || h5; //2张图片都没有的话,不显示图片

                    return (
                        <div
                            className={`ListYoutube-item ${youtubeLink.includes(index) && pc ? 'ListYoutube-active' : ''}`}
                            key={index}
                            onClick={() => playHandle(index, title)}
                        >
                            {hasSrc && (
                                <div className={`ListYoutube-item-in ListYoutube-pos-${position}`}>
                                    <EditorImg pc={pc} h5={h5} />
                                    <div className="ListYoutube-msgs">
                                        {title && (
                                            <p
                                                className="ListYoutube-item-title"
                                                style={{
                                                    color: theme,
                                                }}
                                            >
                                                {title}
                                            </p>
                                        )}
                                        {desc && (
                                            <p
                                                className="ListYoutube-item-desc"
                                                style={{
                                                    color: theme,
                                                }}
                                            >
                                                {desc}
                                            </p>
                                        )}
                                    </div>
                                    <div className="ListYoutube-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                            <path
                                                d="M52.5947 14.98C52.3175 13.8727 51.753 12.858 50.9582 12.0387C50.1634 11.2193 49.1665 10.6241 48.068 10.3134C44.0547 9.33337 28.0014 9.33337 28.0014 9.33337C28.0014 9.33337 11.948 9.33337 7.93471 10.4067C6.83629 10.7175 5.83932 11.3126 5.04452 12.132C4.24971 12.9514 3.68521 13.966 3.40804 15.0734C2.67354 19.1463 2.31426 23.2781 2.33471 27.4167C2.30853 31.5865 2.66783 35.7497 3.40804 39.8534C3.71361 40.9264 4.29076 41.9024 5.08371 42.6872C5.87666 43.4719 6.85862 44.0389 7.93471 44.3334C11.948 45.4067 28.0014 45.4067 28.0014 45.4067C28.0014 45.4067 44.0547 45.4067 48.068 44.3334C49.1665 44.0226 50.1634 43.4275 50.9582 42.6081C51.753 41.7887 52.3175 40.7741 52.5947 39.6667C53.3235 35.6244 53.6828 31.5242 53.668 27.4167C53.6942 23.2469 53.3349 19.0837 52.5947 14.98Z"
                                                fill="#FF0200"
                                            />
                                            <path d="M22.75 35.0467L36.1667 27.4167L22.75 19.7867V35.0467Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            )}
                            {youtubeLink.includes(index) && <iframe className="ListYoutube-iframe" src={item.link}></iframe>}
                        </div>
                    );
                })}
            </div>
            {moreText && moreLink && (
                <a className="ListYoutube-more" href={moreLink}>
                    <span className="more-text">{moreText}</span>
                    <span className="more-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path
                                d="M5.05221 2.26038L9.06055 6.26871L5.05221 10.277"
                                stroke="#3F68E0"
                                strokeWidth="1.05219"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </a>
            )}
        </div>
    );
}
