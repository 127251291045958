'use client';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import { useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import styles from './index.module.scss';

export default function Content({ id, data, attr, theme }: any) {
    const { title, list = [] } = data;
    // 最多6个
    const newList = list.slice(0, 6);
    const [gap, setGap] = useState(0);
    const [swiper, setSwiper] = useState<any>({});
    const [terminal, setTerminal] = useState(0);
    const [disable, setDisable] = useState('prev');

    const handleNext = () => {
        if (terminal >= 2 && newList?.length > 4) {
            setDisable('next');
            swiper.slideTo(newList?.length - 1);
        }
    };

    const handlePrev = () => {
        if (terminal >= 2 && newList?.length > 4) {
            setDisable('prev');
            swiper.slideTo(0);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const resetGap = () => {
                const winW = window.innerWidth;
                if (winW > 1440) {
                    setTerminal(3);
                    return setGap(24);
                } else if (winW > 1024) {
                    setTerminal(2);
                    return setGap(20);
                } else if (winW >= 768) {
                    setTerminal(1);
                    return setGap(16);
                } else {
                    setTerminal(0);
                    return setGap(24);
                }
            };
            resetGap();
            window.addEventListener('resize', resetGap);
            return () => window.removeEventListener('resize', resetGap);
        }
    }, []);

    // useEffect(() => {
    //     if (typeof window !== 'undefined') {
    //         const resetCls = () => {
    //             const parent = document.querySelector(`.${styles.content} .swiper-box`) as HTMLDivElement;
    //             const child = document.querySelector(`.${styles.content} .swiper-box .swiper-item`) as HTMLDivElement;
    //             if (parent && child) {
    //                 const childWidth = newList?.length * child?.offsetWidth + gap * (newList?.length - 1);
    //                 setOver(childWidth > parent?.offsetWidth);
    //             }
    //         };
    //         resetCls();
    //         window.addEventListener('resize', resetCls);
    //         return () => window.removeEventListener('resize', resetCls);
    //     }
    // }, [gap]);

    return (
        <div className={`${styles.content} temp-SwiperCardB temp-hide temp-${theme} temp-${id}`} id={id}>
            <DefaultStyle id={id} attr={attr} />
            {title && <h1 className="SwiperCardB-title">{title}</h1>}
            <div className={`SwiperCardB-in`}>
                <Swiper
                    className={`swiper-box ${(terminal >= 2 && newList?.length > 4) || (terminal < 2 && newList?.length > 1) ? 'over' : ''}`}
                    slidesPerView="auto"
                    modules={[Navigation, Pagination]}
                    navigation={
                        terminal >= 2 && newList?.length > 4
                            ? {
                                  prevEl: `temp-${id} .swiper-button-prev`,
                                  nextEl: `temp-${id} .swiper-button-next`,
                              }
                            : false
                    }
                    pagination={true}
                    spaceBetween={gap}
                    // loop={size < 2}
                    onSwiper={(swiperIns) => setSwiper(swiperIns)}
                >
                    {newList?.map((item: any, index: number) => {
                        const { hot, hotColor, title: productName, desc, btnBuyText, linkBuy, btnMoreText, linkMore, pc, h5 } = item;
                        const hasSrc = pc || h5; //2张图片都没有的话,不显示图片

                        return (
                            <SwiperSlide className={`swiper-item`} key={index}>
                                {hasSrc && (
                                    <div className="banner-img">
                                        <EditorImg pc={pc} h5={h5} />
                                    </div>
                                )}
                                <div className="banner-ms-box">
                                    <div className="banner-ms-in">
                                        {hot && (
                                            <div className="banner-hot" style={{ color: hotColor }}>
                                                {hot}
                                            </div>
                                        )}
                                        {productName && <h2 className="banner-title">{productName}</h2>}
                                        {desc && <p className="banner-desc">{desc}</p>}
                                        <div className="banner-btns">
                                            {btnBuyText && linkBuy && (
                                                <EditorLink
                                                    className="SwiperCardB-link-buy btn-icon"
                                                    href={linkBuy}
                                                    dataLayer={{ event_name: 'buy', product_name: productName, position: 'Product Preview' }}
                                                >
                                                    {btnBuyText}
                                                    <span className="btn-icon-b"></span>
                                                </EditorLink>
                                            )}
                                            {btnMoreText && linkMore && (
                                                <EditorLink
                                                    className="SwiperCardB-link-more btn-icon"
                                                    href={linkMore}
                                                    dataLayer={{
                                                        event_name: 'select_product',
                                                        product_name: productName,
                                                        list_name: title,
                                                        button_name: btnMoreText,
                                                    }}
                                                >
                                                    {btnMoreText}
                                                    <span className="btn-icon-b"></span>
                                                </EditorLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                    {terminal >= 2 && newList?.length > 4 && (
                        <>
                            <button
                                className={`swiper-button-prev swipwe-btn ${disable === 'prev' ? 'swiper-button-disabled' : ''}`}
                                onClick={handlePrev}
                            ></button>
                            <button
                                className={`swiper-button-next swipwe-btn ${disable === 'next' ? 'swiper-button-disabled' : ''}`}
                                onClick={handleNext}
                            ></button>
                        </>
                    )}
                </Swiper>
            </div>
        </div>
    );
}
