import { FormatMoneyLocal } from '@elem/ui-common/utils/FormatMoneyLocal';

export default function CompPriceD({ prefixCls, item, textA, locale }: { prefixCls: string; item: any; textA: any; locale: string }) {
    return (
        <div className={`${prefixCls}-footer-price-d`}>
            <span className={`${prefixCls}-footer-price-d-ms1`}>{FormatMoneyLocal(item.discountPrice || 0, locale)}</span>
            <span className={`${prefixCls}-footer-price-d-ms2`}>{FormatMoneyLocal(item.discountPrice || 0, locale)}</span>
        </div>
    );
}
