import GloLayout from 'lowcode/common/GloLayout';

export default function Content({ id, data = {}, attr = {} }: any) {
    const { title, color, bgColor, position } = data;
    const compStyle = {
        '--editor-text-textarea-color': color,
        '--editor-text-textarea-bg-color': bgColor,
    };
    return (
        <GloLayout className={`editor-text-textarea position-${position}`} id={id} style={compStyle}>
            <div className="editor-text-textarea-in">{title}</div>
        </GloLayout>
    );
}
