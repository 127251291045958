export default function Content({ id, data = {}, attr = {} }: any) {
    const { marginT1, marginT2, marginT3, bg = 'transparent' } = data;
    const itemStyle = {
        '--space-T1': marginT1,
        '--space-T2': marginT2,
        '--space-T3': marginT3,
        '--space-bg': bg,
    } as any;
    return <div className={`editor-space temp-${id}`} id={id} style={itemStyle}></div>;
}
