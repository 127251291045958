'use client';
import EditorImg from 'lowcode/components/EditorImg';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import { useEffect, useState } from 'react';
import { queryByShopifyProductId } from 'lowcode/common/shopify/shopifyFetchClient';
import styles from './index.module.scss';
import ClientStyle from './ClientStyle';
import getLocal from '@/app/utils/getLocal';

export default function Content({ id, data = {}, listA = [], attr = {} }: any) {
    const { locale } = getLocal();
    const [listB, setListB] = useState(data.list || []);
    const getVariantItemFromVariantId = (variantList: any, variantId: string) => {
        let data: any = {};
        variantList?.forEach((item: any) => {
            if (item.variantId === variantId) {
                data = item;
            }
        });

        return data;
    };
    useEffect(() => {
        const initItem = async (item: any) => {
            const data = await queryByShopifyProductId(locale, {
                productId: item.productId,
            });
            return data;
        };
        // 初始化
        const initList = async () => {
            const listB1 = [];
            for (let i = 0; i < listB.length; i++) {
                const data = await initItem(listB[i]);
                listB1.push(data);
            }
            const listB2 = JSON.parse(JSON.stringify(listB));

            for (let j = 0; j < listB.length; j++) {
                const itemB2 = listB2[j];
                const itemB1 = getVariantItemFromVariantId(listB1[j].variantList, itemB2.variantId);

                itemB2.price = itemB1.price;
                itemB2.compareAtPrice = itemB1.compareAtPrice;
            }
            setListB(listB2);
            console.log('data', listB1);
        };
        initList();
    }, []);
    return (
        <div className={`${styles.content} temp-hide temp-${id}`}>
            <DefaultStyle id={id} attr={attr} />
            <ClientStyle id={id} attr={attr} />
            <ul>
                {listB.map((item: any, index: number) => {
                    return (
                        <li key={index}>
                            <div className="ShopifyGoodsA-imgbox">
                                <EditorImg pc={item.src} />
                            </div>
                            <div className="ShopifyGoodsA-title">{item.title}</div>
                            <div className="ShopifyGoodsA-desc">{item.desc}</div>
                            <div className="ShopifyGoodsA-price">{item?.price}</div>
                            <div className="ShopifyGoodsA-price">{item?.compareAtPrice}</div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
